// 用于全局
export const totalCommon = {
    methods:{
        // 用于全局
        test1(){
            console.log("使用了全局mixin")
        }
    }
}
// 用于局部
export const common = {
    methods:{
        test2(str){
            console.log(str)
        }
    }
}