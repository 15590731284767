<template>
    <div class="yeBox">
        <ul>
            <li v-for="(item,index) in ywInfo.ywList" :key="index" :class="ywInfo.yeIndex == index ? 'cur' : ''"  @click="tabClick(index)"  class="animate__animated animate__fadeInUp wow">
                <h2>{{item.title}}<span v-if="ywInfo.yeIndex == index">→</span></h2>
                <p>{{item.remark}}</p>
            </li>
        </ul>
        <div class="img">
            <img :src="ywInfo.curImg" class="animate__animated wow" :class="ywInfo.imgShow ? 'animate__fadeInUp' : 'animate__fadeInRight'" />
            <div class="iconBox animate__animated wow"  :class="ywInfo.imgShow ? 'animate__fadeInUp' : 'animate__fadeInRight'" >
                <div class="iconBoxbg animate__animated wow" v-if="ywInfo.ywList[ywInfo.yeIndex]"  :class="ywInfo.imgShow ? 'animate__fadeInUp' : 'animate__fadeInRight'">
                    <div v-for="(item1,index) in ywInfo.ywList[ywInfo.yeIndex].writer" :key="index" class="iconItem"><img :src="item1.icon" />{{item1.name}}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<style  lang="scss" scoped>
    .yeBox{
        display: flex;
        height: 6.84rem;
        margin: 50px 0;
        ul{
            width: 3.2rem;
            display: flex;
            flex-direction: column;
            padding-right: .4rem;
            li{
                flex: 1;
                padding: 0 20px;
                overflow: hidden;
                cursor: pointer;
                h2{
                font-size: .16rem;
                padding: 15px 0 10px;
                display: flex;
                justify-content: space-between;
                font-family: 'SYHeavy';
                span{
                font-size: 20px;
                }
                }
                p{
                    font-size: 13px;
                    line-height: 20px;
                    font-family: 'SYMedium';
                }
            }
            li.cur{
                color: #ef6d1a;
                // color: #fff;
            }
            
        }
        .img{
            width: 9.25rem;
            height: 6.84rem;
            position: relative;
            img{
                width: 100%;
                height: 100%;
            }
            .iconBox{
                position: absolute;
                left: -.4rem;
                bottom: .2rem;
                width: 102%;
                padding: .2rem  0 .15rem .3rem;
                box-sizing: border-box;
                background: #FDBC00;
                border-radius: .2rem 0 0 0;
                .iconBoxbg{
                    background: #fff;
                    border-radius: .2rem 0 0 0;
                    width: 100%;
                    height: 1.4rem;
                    padding: .5rem .6rem;
                    box-sizing: border-box;
                    display: flex;
                    justify-content:space-between;
                    box-sizing: border-box;
                    .iconItem{
                        display: flex;
                        flex-direction: column;
                        font-size: .22rem;
                        justify-content: center;
                        align-items: center;
                        font-family: 'SYHeavy';
                        img{
                        width: .6rem;
                        height: .6rem;
                        margin-bottom: .16rem;
                    }
                    }
                }
            }
        }
    }
</style>
<script>

import {onMounted,useContext,nextTick } from "vue";
import {WOW} from 'wowjs' 
import 'animate.css'
export default {
    props:['ywInfo'],
    setup(){
        // console.log(props.ywInfo.ywList.writer)
        onMounted(()=>{
            nextTick(()=>{
                var wow = new WOW({
                boxClass: 'wow',    //需要执行动画元素的Class
                animateClass: 'animated',    //animation.css动画的Class
                offset: 10,    //距离可视区域多少开始执行动画
                mobile: true,    //是否在移动设备执行动画
                live: true,    //异步加载的内容是否有效
                iteration:2
                })
                wow.init()
            })
        })
        
        const ctx = useContext();
        function tabClick(index){
            ctx.emit('tabClick',index)
        }
        
        return{
            // state,
            tabClick
        }
        
    }
}
</script>