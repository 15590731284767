<template>
  <div class="homeBox">
    <!-- 轮播图 -->
    <Banner :imgArr="list.arr" v-if="list.arr!=''" />
    <div class="container">
      <Title tit="产品服务" enTit1="Product"  enTit2="service" :isShow="true"/>
      <!-- 丰富的产品与服务 -->
      <Product :productData="list.productData" :jieshu="list.jieshu" />
      <Title tit="标准化种植" enTit1="Standardized  "  enTit2=" planting" :isShow="true"/>
      <Yewu :ywInfo="list.ywInfo" @tabClick="tabClick" />
      <!-- <Section3 :section3List="list.section3List" /> -->
    </div>
    <Zduan :zdList= "list.zdList" :jieshu="list.jieshu"/>

    <!-- 联系我们 优势 -->
    <div class="container" style="margin:1rem auto">
      <Title tit="我们的优势" enTit1="Our"  enTit2="advantage" :isShow="false"/>
      <Youshi :list="list.youshiList" :info="list.info.content" :contact="list.contact.content"  />
    </div>
    
    <!-- 企业动态 -->
    <div class="container">
      <Title tit="来村科技企业最新动态"  enTit1="Latest"  enTit2="news" :isShow="false" />
      <News :newsList="list.newsList"  />  
    </div>
      
  </div>

  <!--  v-if="state==='pc'" -->
  <!-- <div v-else class="mobile">
  </div> -->
</template>

<script>
// import Vue from 'vue'
import Banner from "@/components/banner.vue"
import Title from "./components/title.vue"
import Product from "./components/productCard.vue";
import Yewu from "./components/ywCard.vue";
import Youshi from "./components/youshi.vue";
// import Section3 from "@/components/liangdian.vue";
import News from "./components/news1.vue";
import Zduan from "./components/zdCard.vue";
import { useStore } from "vuex";
import {computed,onMounted, reactive} from "vue";
import { getFissionCourseList,getbannerData} from "../../api/request"
// import axios from "axios";
import {WOW} from 'wowjs' 
import 'animate.css'
export default {
  name: "Home",
  components: {
    Title,
    Product,
    Yewu,
    // Section3,
    Zduan,
    News,
    Youshi,
    Banner
  },
  setup() {
    // const arr = [
    //   {img: require("@/assets/images/banner/banner1.jpg"),},
    //   {img: require("@/assets/images/banner/banner2.jpg"),},
    //   {img: require("@/assets/images/banner/banner3.jpg"),},
    // ];
 
    let list = reactive({
      jieshu:false,
      arr:[],
      productData:[],
      ywInfo:{
        ywList:[],
        yeIndex:0,
        imgShow:false,
        curImg:""
      },
      section3List:[],
      zdList:[],
      youshiList:[],
      info:{},
      contact:{},
      newsList:[]
    })
    const state = computed(() => useStore().state.client);
    onMounted(()=>{
      getList()
      var wow = new WOW({
        boxClass: 'wow',    //需要执行动画元素的Class
        animateClass: 'animated',    //animation.css动画的Class
        offset: 100,    //距离可视区域多少开始执行动画
        mobile: true,    //是否在移动设备执行动画
        live: true    //异步加载的内容是否有效
      })
      wow.init()
    })
    function tabClick(index){
      list.ywInfo.yeIndex = index
      list.ywInfo.curImg = list.ywInfo.ywList[index].photo
      list.ywInfo.imgShow = !list.ywInfo.imgShow
    }
    function getList() {
      getFissionCourseList().then((res) => {
        // console.log(res);
        if(res.code == 200){
          list.jieshu = true
          list.productData = res.data.article1
          // list.ywList = res.data.article2
          let ywList = res.data.article2
          let iconArr = [
            [{name:"标准化播种",icon:require('@/assets/images/index/bz.png')},{name:"标准化施肥",icon:require('@/assets/images/index/sf.png')},{name:"标准化灌溉",icon:require('@/assets/images/index/gg.png')},{name:"标准化喷药",icon:require('@/assets/images/index/py.png')}],
            [{name:"智慧识别",icon:require('@/assets/images/index/zhsb.png')},{name:"自我学习",icon:require('@/assets/images/index/zwxx.png')},{name:"病虫害库",icon:require('@/assets/images/index/bchk.png')},{name:"专家指导",icon:require('@/assets/images/index/zjzd.png')}],
            [{name:"物联网系统",icon:require('@/assets/images/index/wlwxt.png')},{name:"区块链技术",icon:require('@/assets/images/index/qyljs.png')},{name:"智慧溯源",icon:require('@/assets/images/index/zhsy.png')},{name:"一物一码",icon:require('@/assets/images/index/ywym.png')}],
            [{name:"乡村治理",icon:require('@/assets/images/index/xczl.png')},{name:"智慧农业",icon:require('@/assets/images/index/zhny.png')},{name:"数字农旅",icon:require('@/assets/images/index/sznl.png')},{name:"村民综合服务",icon:require('@/assets/images/index/zhfw.png')}],
            [{name:"模式多元化",icon:require('@/assets/images/index/msdyh.png')},{name:"物流体系",icon:require('@/assets/images/index/wltx.png')},{name:"供应链建设",icon:require('@/assets/images/index/gyljs.png')},{name:"农产品品牌化",icon:require('@/assets/images/index/ncppph.png')}],
            [{name:"方案设计",icon:require('@/assets/images/index/fasj.png')},{name:"投标协助",icon:require('@/assets/images/index/zbxz.png')},{name:"研发生产",icon:require('@/assets/images/index/yfsc.png')},{name:"品类齐全",icon:require('@/assets/images/index/plqq.png')}],
          ]

          // ywList.forEach(item=>{
          //   item.views = iconArr[0]
          // })
          for(let i=0;i<ywList.length;i++){
            ywList[i].writer = iconArr[i]
          }

          list.ywInfo ={
            ywList:ywList,
            yeIndex:0,
            imgShow:false,
            curImg:res.data.article2[0].photo
          }
          list.section3List = res.data.article3
          list.info = res.data.article4[0]
          list.contact = res.data.article4[1]

          let youshiImg=[require('@/assets/images/index/photo1.jpg'),require('@/assets/images/index/photo2.jpg'),require('@/assets/images/index/photo3.jpg'),require('@/assets/images/index/photo4.jpg')]
          list.youshiList = res.data.article5
          for(let i=0;i<list.youshiList.length;i++){
            list.youshiList[i].writer = youshiImg[i]
            list.youshiList[i].views = false
          }
          // console.log(list.youshiList)
          list.newsList =  res.data.article6

          list.zdList=[
            {id:1,title:"微信小程序",icon:require("@/assets/images/index/xcs.png")},
            {id:2,title:"H5",icon:require("@/assets/images/index/h5e.png")},
            {id:3,title:"安卓.IOS",icon:require("@/assets/images/index/az.png")},
            {id:4,title:"公众号",icon:require("@/assets/images/index/wx.png")},
            {id:5,title:"PC",icon:require("@/assets/images/index/pc.png")},
          ]
        }
      }); 
      getbannerData({id:29}).then((res)=>{
        if(res.code == 200){
          list.arr = res.data
        }
      })
    }

    return {
      list,
      state,
      getList,
      tabClick
    };
  },
};
</script>

<style lang="scss" scoped>
.homeBox{
  margin-top: 0.8rem;
}
.container {
  width: 12rem;
  height: 100%;
  margin: auto;
}
.bottomBox{
    display: flex;
    width: 100%;
    margin: .4rem 0;
    .bottomLeft{
      flex: 1;
      flex-shrink: 0;
      .box{
        display: flex;
        flex: 1;
        .box1,.box4{
          flex: 1;
          background: #EF6D1A;
          color: #fff;
          font-size: .14rem;
          box-sizing: border-box;
          h2{
            font-size: .20rem;
            margin: .3rem 0 .15rem 0;
            font-weight: normal;
            padding: .4rem .3rem 0;
          }
          p{
            font-size: .16rem;
            line-height: .3rem;
            padding: 0 .3rem .4rem;
          }
        }
        .box2,.box3{
          overflow: hidden;
          flex: 1;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .box4{
          background: #0D8D3F;
          div{
            padding: .4rem;
            font-size: .16rem;
            line-height: .5rem;
          }
        }
      }
    }
    .youshiRight{
      flex: 1;
      flex-shrink: 0;
      margin: 0 1rem 0 .5rem;
      background: #fff;
      h2{
        font-size: .3rem;
        font-weight: normal;
        padding: .5rem 0 .3rem;
        color: #333;
      }
      ul{
      
        li{
        display: flex;
        margin: 0 0 .45rem;
        img{
          width: .72rem;
          height: .72rem;
          margin: .1rem .2rem 0 0;
        }
        .fontBox{
          display: flex;
          flex-direction: column;
          align-content: center;
          justify-content: center;
          padding: 0 2rem 0 0 ;
          h3{
            font-size: .16rem;
            padding: .1rem 0;
            color: #333;
          }
          p{
            font-size: .14rem;
            color: #999;
            line-height: .26rem;
        }
        }
        
      }
      }
    }
  }
.el-carousel {
  margin-bottom: 0.8rem;
}
.mobile{
  text-align: center;
  box-sizing: border-box;
  padding-top: 1rem;
  h2{
    font-size: .66rem;
    color: #3f3f3f;
  }
  h3{
    font-size: .66rem;
    color: #3f3f3f;
    margin-bottom: .72rem;
  }
  a{
    display: block;
    width: 3.6rem;
    line-height: 1rem;
    background-color: #12cd5a;
    font-size: .32rem;
    color: #fff;
    margin: auto;
    margin-bottom: .4rem;
  }
  .compatible{
    color: #474747;
    font-size: .14rem;
    margin-bottom: .8rem;
  }
  .footer{
    color: #0bd561;
    font-size: .4rem;
  }
}

</style>
