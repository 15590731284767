<template>
    <div class="newsBox">
        <img :src="state.curInfo.photo" v-if="newsList.length > 0" class="animate__animated wow" :class="state.curInfoShow ? 'animate__fadeInUp' : 'animate__fadeInDown' " />
        <ul class="listBox animate__animated animate__fadeInUp wow">
            <li class="listItem" v-for="(item,index) in newsList" :key="index" v-on:mouseover="listOver(index)" @click="href(item.id)">
                <span class="triangle" v-if="state.curIndex !== index"></span>
                <div class="itemRight">
                    <div v-if="state.curIndex == index" class="curTime"><span>{{formatDate3(item.create_time)}}</span><span>{{formatDate2(item.create_time)}}</span></div>
                    <div v-else class="curTime1">{{formatDate1(item.create_time)}}</div>
                    <div class="titBox">
                        <span :class="state.curIndex == index ? 'curTit' : ''">{{item.title}}</span>
                        <p v-if="state.curIndex == index">{{newsList[state.curIndex].remark}}</p>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>
<style  lang="scss" scoped>
a{color: #333;}
.section{
    display: flex;
    li{
        flex: 1;
        margin: 0 .5rem;
        font-size: .14rem;
        cursor: pointer;
        .img{
            width: 2.8rem;
            height: 2rem;
            margin-bottom: .1rem;
        }
        .typeBox{
            color: #666;
            animation-delay: .1s;
        }
        h2{
            font-size: .18rem;
            margin: .1rem 0;
            animation-delay: .2s;
        }
        p{
            line-height: .26rem; 
            color: #333;
            height: .48rem;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            animation-delay: .3s;
        }
        span{
            display: block;
            padding: .2rem 0;
            color: orangered;
            animation-delay: .4s;
        }
    }
}
.newsBox{
    display: flex;
    font-size: .13rem;
    font-family: 'SYNormal';
    margin-bottom: 1rem;
    img{
        width: 6rem;
        height: 3rem;
        margin-right: .4rem;
    }
    .listBox{
        flex: 1;
        .listItem{
            display: flex;
            align-items: center;
            width: 100%;
            border-bottom: 1px dashed #aaa;
            color: #666;
            line-height: .3rem;
            padding: .12rem 0;
            cursor: pointer;
            .itemRight{
                display: flex;
                font-family: 'SYMedium';
                .curTime{
                    display: flex;
                    flex-direction: column;
                    width: .7rem;
                    border: 1px solid #aaa;
                    margin-right: .15rem;
                    span{
                        display: block;
                        text-align: center;
                    }
                    span:nth-child(1){
                        font-size: .18rem;
                    }
                    span:nth-child(2){
                        background: #aaa;
                        font-size: .13rem;
                        color: #fff;
                    }
                }
                .curTime1{
                    margin:0 .1rem;
                }
            }
            .titBox{
                .curTit{
                    color: #000;
                    font-size: .14rem;
                }
                p{
                    height: .2rem;
                    line-height: .3rem;
                }
            }
        }
    }   
}
.triangle{
	width: 0;
	height: 0;
	border-left: 5px solid #aaa;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;
}
</style>
<script>
import { useRouter } from 'vue-router'
import {onMounted, reactive,watchEffect} from "vue";
import {WOW} from 'wowjs' 
import 'animate.css'
import { formatDate } from '@/assets/utils/utils';
export default {
    props:['newsList'],
    setup(props){
        onMounted(()=>{
            // console.log(props)
            var wow = new WOW({
            boxClass: 'wow',    //需要执行动画元素的Class
            animateClass: 'animated',    //animation.css动画的Class
            offset: 100,    //距离可视区域多少开始执行动画
            mobile: true,    //是否在移动设备执行动画
            live: true    //异步加载的内容是否有效
            })
            wow.init()
        })
        //过滤
        const state = reactive({
            curIndex:0,
            curInfo:props.newsList[0],
            curInfoShow:false
        })
       function formatDate1(time) {
            time = time * 1000
            let date = new Date(time)
            return formatDate(date, 'yyyy-MM-dd')
        }
        function formatDate2(time) {
            time = time * 1000
            let date = new Date(time)
            return formatDate(date, 'yyyy-MM')
        }
        function formatDate3(time) {
            time = time * 1000
            let date = new Date(time)
            return formatDate(date, 'dd')
        }
        function listOver(index){
            state.curIndex = index,
            state.curInfoShow = !state.curInfoShow
        }
        watchEffect(()=>{
            state.curInfo = props.newsList[state.curIndex]
        })
        //  :to="{ name: 'newsdetails', params: { id: item.id }}"
        let router = useRouter();
        const href=(id)=>{
            router.push({
            //传递参数使用params的话，只能使用name指定(在route.js里面声明name)
            path:"/newDetail",
            query:{
                id:id
            }
            })
        }
        return{
            state,
            href,
            listOver,
            formatDate1,
            formatDate2,
            formatDate3
        }
    }
}
</script>