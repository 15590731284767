<template>
  <div>
    <Header :isScrollTop="isScrollTop" />
    <router-view />
    <div v-if="state==='pc'">
      <Footer />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { onBeforeMount, ref,watchEffect } from '@vue/runtime-core';
export default {
  components:{
      Header,
      Footer
  },
  setup(){
    const store=useStore()
    const state = ref('pc')
    const setMode=()=>{
      let w=document.documentElement.clientWidth;
      if(w>960){
        state.value = 'pc'
        store.state.client==='mobile'?store.commit('setWidth','pc'):null
        return
      }
      state.value = 'mobile'
      store.state.client==='pc'?store.commit('setWidth','mobile'):null
    }
    onBeforeMount(setMode)
    window.addEventListener('resize',setMode)



    let isScrollTop = ref(true)
    function scrollTopFn(){
      let x1 = document.documentElement.scrollTop || document.body.scrollTop;
      // console.log(x1)
      if(x1>20){
        isScrollTop.value = false
      }else{
        isScrollTop.value = true
      }
    }
     watchEffect(()=>{
       window.addEventListener('scroll',scrollTopFn,true)
      // console.log(typeof isScrollTop.value)
    })
    return {
      state,
      isScrollTop,
      scrollTopFn
    }
  }  
}
</script>

<style lang="scss">

</style>
