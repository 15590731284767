import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import './assets/css/reset.min.css'
import './assets/font/font.css'

import { totalCommon } from './minix'

// import Carousel3d from 'vue-carousel-3d';


// import Vue from 'vue' 
// import WOW from 'wowjs'
// Vue.use(WOW)
// Vue.prototype.$WOW=WOW

const app = createApp(App)
app.mixin(totalCommon)

app.use(store).use(router).use(ElementPlus).mount('#app')
