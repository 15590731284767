<template>
    <div class="ysTop">
        <div class="left">
            <div class="ysListNum">
                <div class="ysListNumItem" v-for="(item,index) in list" :key="index" @click="numClick(index)">
                    <div class="span" :class="state.ysCurIndex == index ? 'spanCur' : ''  ">{{'0'+(index+1)}}</div>
                    <div class="line" :class="state.ysCurIndex == index ? 'curLine' : ''"></div>
                </div>
            </div>
            <div class="ysCur animate__animated wow"  :class="state.ysCurShow ? 'animate__fadeInUp' : 'animate__fadeInRight'" v-if="list.length >0" :key="currentFlag">
                <!-- {{list[state.ysCurIndex].writer}} -->
                <img :src="list[state.ysCurIndex].writer" />
                <div class="infoBox animate__animated wow" :class="state.ysCurShow ? 'animate__fadeInUp' : 'animate__fadeInRight'">
                    <div class="infoLeft animate__animated wow" :class="state.ysCurShow ? 'animate__fadeInUp' : 'animate__fadeInRight'">
                        <h3>{{list[state.ysCurIndex].title}}</h3>
                        <p>{{list[state.ysCurIndex].remark}}</p>
                    </div>
                    <div class="infoRight"><img :src="list[state.ysCurIndex].photo" /></div>
                </div>
            </div>
        </div>
        <div class="right">
            <div class="intro">
                <h3>来村网</h3>
                <div v-html="info"></div><div v-html="contact"></div>
            </div>
            <div class="indexNum animate__animated wow"  :class="state.ysCurShow ? 'animate__fadeInUp' : 'animate__fadeInRight'"><span></span>{{'0'+(state.ysCurIndex+1)}}</div>
        </div>
    </div>
    <div class="ysList">
        <div class="ysItem animate__animated wow"  :class="[state.ysCurIndex == index ? 'ysItem1' : '' , state.ysCurShow ? 'animate__fadeInLeft' : 'animate__fadeInRight']" v-for="(item,index) in list" :key="index">
            <div class="ysItemInfo"  v-if="state.ysCurIndex !== index" @click="numClick(index)">
                <img :src="item.writer" />
                <div class="rightInfo">
                    <h4>{{item.title}}</h4>
                    <p>{{item.remark}}</p>
                    <img src="@/assets/images/index/gd.png" />
                </div>
            </div>
        </div>
    </div>
</template>
<style  lang="scss" scoped>
    .ysTop{
        display: flex;
        height: 4rem;
        font-family: 'SYMedium';
        .left{
            flex: 1;
            display: flex;
            .ysListNum{
                width: 2rem;
                height: 4rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .ysListNumItem{
                    width: 100%;
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    height: 100%;
                    cursor: pointer;
                    .span{
                        width: .8rem;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        background: #F7F8F9;
                        font-size: .2rem;

                    }
                    .spanCur{
                        color: #FDBC00;
                    }
                    .line{
                        width: 1.1rem;
                        height: 2px;
                        margin-left: -.1rem;
                    }
                    .curLine{
                        background: #000;
                    }
                    
                }
            }
            .ysCur{
                width: 6rem;
                height: 100%;
                position: relative;
                img{
                    width: 100%;
                    height: 100%;
                }
                .infoBox{
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 3.3rem;
                    // height: 1.1rem;
                    background: #fff;
                    font-size: .12rem;
                    display: flex;
                    padding: .2rem;
                    box-sizing: border-box;
                    color: #FDBC00;
                    .infoLeft{
                        margin-right: .16rem;
                        h3{
                            font-weight: normal;
                            font-size: .14rem;
                            margin-bottom: .05rem;
                        }
                        p{
                            line-height: .18rem;
                        }
                    }
                    .infoRight{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                        img{
                        width: .5rem;
                        height: .5rem;
                        vertical-align: middle;
                    }
                    }
                }
            }
        }
        .right{
            width: 4rem;
            margin-left: .5rem;
            font-size: .13rem;
            .intro{
                h3{
                    margin-bottom: .2rem;
                    font-size: .18rem;
                }
                div{
                    margin: .2rem 0;
                    line-height: .24rem;
                }
            }
            .indexNum{
                display: flex;
                line-height: .3rem;
                font-size: .8rem;
                span{
                    flex: 1;
                    height: .15rem;
                    border-bottom: 1px solid #979797;
                    margin-right: .4rem;
                }
            }
            
        }
    }
    .ysList{
        display: flex;
        font-family: 'SYMedium';
        justify-content: space-between;
        margin-top: 1rem;
        .ysItem{
            flex: 1;
            .ysItemInfo{
                display: flex;
                margin-right: .3rem;
                cursor: pointer;
                img{
                    width: 1.82rem;
                    height: 1.29rem;
                    margin-right: .2rem;
                }
                .rightInfo{
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    h4{
                        font-size: .16rem;
                    }
                    p{
                        height: 1rem;
                        font-size: .12rem;
                        line-height: .18rem;
                        color: #9E9FA0;
                        overflow: hidden;
                    }
                    img{
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: .19rem;
                        height: .08rem;
                    }
                }
            }
        }
        .ysItem1{flex: 0;}
    }
</style>
<script> 
    import {onMounted,nextTick, reactive,watch} from "vue";
    import {WOW} from 'wowjs' 
    import 'animate.css'
    export default {
        props:['list','info','contact'],
        setup(props){
            // console.log(props.ywInfo.ywList.writer)
            onMounted(()=>{
                nextTick(()=>{
                    var wow = new WOW({
                    boxClass: 'wow',    //需要执行动画元素的Class
                    animateClass: 'animated',    //animation.css动画的Class
                    offset: 10,    //距离可视区域多少开始执行动画
                    mobile: true,    //是否在移动设备执行动画
                    live: true,    //异步加载的内容是否有效
                    iteration:2
                    })
                    wow.init()
                })
                
            })
            const state = reactive({
                ysCur:{},
                ysCurIndex:0,
                ysCurShow:false,
                list:[],
                list1:[]
            })
            // const ctx = useContext();
            function numClick(index){
                state.ysCurIndex = index
                state.ysCur = props.list[index]
                state.ysCurShow = !state.ysCurShow
            }
            watch(state.ysCurIndex,()=>{
                console.log(state.ysCurIndex)
                var wow = new WOW({
                    boxClass: 'wow',    //需要执行动画元素的Class
                    animateClass: 'animated',    //animation.css动画的Class
                    offset: 10,    //距离可视区域多少开始执行动画
                    mobile: true,    //是否在移动设备执行动画
                    live: true,    //异步加载的内容是否有效
                    iteration:2
                })
                wow.init()
            })
            return{
                state,
                numClick
            }
            
        }
    }

</script>