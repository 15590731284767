<template>
    <div class="box">
        <div class="leftTit animate__animated animate__bounceInLeft wow" >
            <div class="lineBox animate__animated animate__slideInDown wow"><span></span><span></span><span></span></div>
            <div class="chinaBox  animate__animated animate__fadeIn wow">{{tit}}</div>
            <div class="engBox animate__animated animate__slideInUp wow"><span>{{enTit1}}</span><span>{{enTit2}}</span></div>
        </div>
        <div class="rightTit animate__animated animate__bounceInRight wow" v-if="isShow"></div>
    </div>
  <!--  -->
</template>

<script>

import {onMounted} from "vue";
import {WOW} from 'wowjs' 
import 'animate.css'
export default {
  props:['tit','enTit1','enTit2','isShow'],
  setup(){
    onMounted(()=>{
        var wow = new WOW({
        boxClass: 'wow',    //需要执行动画元素的Class
        animateClass: 'animated',    //animation.css动画的Class
        offset: 100,    //距离可视区域多少开始执行动画
        mobile: true,    //是否在移动设备执行动画
        live: true    //异步加载的内容是否有效
    })
	wow.init()
    //data-wow-duration：更改动画持续时间 data-wow-delay：动画开始前的延迟
    // data-wow-offset：开始动画的距离（与浏览器底部相关）
    // data-wow-iteration：动画的次数重复（无限次：infinite）
	})
  }
}
</script>
<style lang="scss" scoped>

.box{
    width: 12rem;
    // height: 1.4rem;
    position: relative;
    margin-bottom: .8rem;
    .leftTit{
        display: flex;
        flex-direction: column;
        .lineBox{
            display: flex;
            height: .11rem;
            span{
                display: block;            
                background: #FCB80C;
                height: .11rem;
                margin-right: .09rem;
            }
            span:nth-child(1){
                width: 1.19rem;
            }
            span:nth-child(2){
                width: .21rem;
            }
            span:nth-child(3){
                width: .12rem;
            }
        }
        .chinaBox{
            color: #212434;
            font-size: .36rem;
            margin: .2rem 0 .1rem;
            font-family: 'HYQiHei';
        }
        .engBox{
            display: flex;
            span{
                display: block;
                font-size: .36rem;
                font-family: 'HYQiHei';
            }
            span:nth-child(1){
                color: #212434;
                margin-right: .2rem;
            }
            span:nth-child(2){
                color: #FEBD01;
            }
        }
    }
    .rightTit{
        position: absolute;
        width: 1.4rem;
        height: .46rem;
        background: #FDBC00;
        bottom: 0;
        right: 0;
    }

}
.my-element {
  --animate-duration: 5s;
}
// :root {
//   --animate-duration: 3s;
//   --animate-delay: 3s;
// }
</style>