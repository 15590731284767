import http from './http'
// const api_key = '233d4cad34c34d343785s34dw3er3ds3234esd4'
// let sign = process.env.VUE_APP_SIGN
// if (process.env.NODE_ENV === 'production') {
//   sign = localStorage.getItem("sign")
// } else {
//   sign = '17a1920fdc3c3c25d1bf7c6dd724f5cf'
// }
const sign = 'c4c6cff9b7ec6b87fa953f77ec7e624b'

const post = (url, data = {}) =>
  http.post(
    url,
    Object.assign(
    {
        //api_key: api_key,
        sign,
    },
      data
    )
  )
const get = (url, params = {}) =>
  http.get(
    url,
    Object.assign(
    {
        //api_key: api_key,
        sign
    },
      params
    )
  )

// 接口说明
// 首页
const getFissionCourseList = data => post('index/index', data)
// 底部
const getFooterData = data => post('index/index_footer', data)
// 广告
const getbannerData = data => post('index/get_banner', data)
// 关于我们
const getAboutUsNav = data => post('index/about', data)
const getAboutUsData = data => post('index/aboutarticle ', data)
// 关于我们--发展历程
const getDevelop = data => post('index/develop ', data)
const getDevelopcontent = data => post('index/developcontent ', data)
// 硬件产品
const getProductsNav = data => post('index/products', data)
const getProductsList = data => post('index/productsarticle', data)
const getProductsDetail = data => post('index/HardwareDetails ', data)
// 新闻
const getNewsList = data => post('index/newslist ', data)
const getNewDetail = data => post('index/newsDetails ', data)
// 成功案例
const getCaseList = data => post('index/caselist', data)
const getCaseDetail = data => post('index/caseDetails', data)
// 业务领域-数字农业
const getYewNav = data => post('index/business', data)
const getYewDetail = data => post('index/businesslist', data)
// 业务领域-智慧农业
const getWitlist = data => post('index/witlist', data)
// 业务领域-数字农业
const getEnterprise = data => post('index/enterprise', data)
// 业务领域-数字乡村
const getVillage = data => post('index/village', data)
// 业务领域-全链溯源
const getSource = data => post('index/source', data)
// 业务领域-全链溯源
const getCounty = data => post('index/county', data)
// 软件产品
const getSoftware = data => post('index/software', data)

const getGetrequs = params => get('index/index', params)
//接口导出
export {
  getFissionCourseList,
  getFooterData,
  getbannerData,
  getAboutUsNav,
  getAboutUsData,
  getDevelop,
  getDevelopcontent,
  getProductsNav,
  getProductsList,
  getProductsDetail,
  getNewsList,
  getNewDetail,
  getCaseList,
  getCaseDetail,
  getYewNav,
  getYewDetail,
  getWitlist,
  getEnterprise,
  getVillage,
  getSource,
  getCounty,
  getSoftware,
  getGetrequs
}