<template>
  <div :class="state">

    <div class="navBox animate__animated animate__fadeInDown">
      <div class="container">
      <!-- 左侧logo -->
        <a href="" class="logo">
          <img src="@/assets/images/logo.png" />
        </a>
        <!-- 右侧导航 -->
        <el-menu v-if="state==='pc'" :default-active="activeIndex" mode="horizontal" text-color='#464646' active-text-color='#898989'  router @select="handleSelect">
        <el-menu-item index="home">首页</el-menu-item>
        <el-submenu  index="product">
          <template v-slot:title>硬件产品</template>
          <el-menu-item index="productList" :route="{ path:'productList', query: { id: 11} }"><img src="@/assets/images/navicon/yj//zngg.png" /><span>智能灌溉设备</span></el-menu-item>
          <el-menu-item index="productList" :route="{ path:'productList', query: { id: 8} }"><img src="@/assets/images/navicon/yj/qxhjjc.png" /><span>气象环境检测设备</span></el-menu-item>
          <el-menu-item index="productList" :route="{ path:'productList', query: { id: 9} }"><img src="@/assets/images/navicon/yj/trhjjc.png" /><span>土壤环境检测设备</span></el-menu-item>
          <el-menu-item index="productList" :route="{ path:'productList', query: { id: 10} }"><img src="@/assets/images/navicon/yj/znjksxt.png" /><span>智能监控摄像头</span></el-menu-item>
          <el-menu-item index="productList" :route="{ path:'productList', query: { id: 12} }"><img src="@/assets/images/navicon/yj/znnjx.png" /><span>智能农具箱</span></el-menu-item>
          <el-menu-item index="productList" :route="{ path:'productList', query: { id: 13} }"><img src="@/assets/images/navicon/yj/sbpj.png" /><span>设备配件</span></el-menu-item>
        </el-submenu >
        <el-submenu  index="product1">
          <template v-slot:title>软件产品</template>
          <el-menu-item index="product1" :route="{ path:'product1', query: { id: 34} }"><img src="@/assets/images/navicon/rj/zzgl.png" /><span>种植管理系统</span></el-menu-item>
          <el-menu-item index="honor" :route="{ path:'product1', query: { id: 35} }"><img src="@/assets/images/navicon/rj/bchsb.png" /><span>病虫害识别系统</span></el-menu-item>
          <el-menu-item index="product1" :route="{ path:'product1', query: { id: 36} }"><img src="@/assets/images/navicon/rj/zngg.png" /><span>智能灌溉系统</span></el-menu-item>
          <el-menu-item index="product1" :route="{ path:'product1', query: { id: 37} }"><img src="@/assets/images/navicon/rj/nqyj.png" /><span>农情预警系统</span></el-menu-item>
          <el-menu-item index="product1" :route="{ path:'product1', query: { id: 38} }"><img src="@/assets/images/navicon/rj/wghgl.png" /><span>网格化管控系统</span></el-menu-item>
          <el-menu-item index="product1" :route="{ path:'product1', query: { id: 39} }"><img src="@/assets/images/navicon/rj/syxt.png" /><span>农产品溯源系统</span></el-menu-item>
          <el-menu-item index="product1" :route="{ path:'product1', query: { id: 40} }"><img src="@/assets/images/navicon/rj/szxc.png" /><span>数字乡村</span></el-menu-item>
        </el-submenu>
        <el-submenu  index="business">
          <template v-slot:title>业务领域</template>
          <el-menu-item index="business1" :route="{ path:'business1', query: { id: 2 } }"><img src="@/assets/images/navicon/yw/szny.png" /><span>数字农业</span></el-menu-item>
          <el-menu-item index="business2" :route="{ path:'business2', query: { id: 3} }"><img src="@/assets/images/navicon/yw/zhny.png" /><span>智慧农业</span></el-menu-item>
          <el-menu-item index="business3" :route="{ path:'business3', query: { id: 4} }"><img src="@/assets/images/navicon/yw/szqy.png" /><span>数字企业</span></el-menu-item>
          <el-menu-item index="business4" :route="{ path:'business4', query: { id: 5} }"><img src="@/assets/images/navicon/yw/szxc.png" /><span>数字乡村</span></el-menu-item>
          <el-menu-item index="business5" :route="{ path:'business5', query: { id: 6} }"><img src="@/assets/images/navicon/yw/qlsy.png" /><span>全链溯源</span></el-menu-item>
          <el-menu-item index="business6" :route="{ path:'business6', query: { id: 7} }"><img src="@/assets/images/navicon/yw/xyds.png" /><span>县域电商</span></el-menu-item>
        </el-submenu>
        <el-menu-item index="case">成功案例</el-menu-item>
        <el-submenu  index="">
          <template v-slot:title>关于我们</template>
          <el-menu-item index="aboutus" :route="{ path:'aboutus', query: { id: 29} }"><img src="@/assets/images/navicon/ab/qyjj.png" /><span>企业简介</span></el-menu-item>
          <el-menu-item index="honor" :route="{ path:'aboutus', query: { id: 30} }"><img src="@/assets/images/navicon/ab/lcry.png" /><span>来村荣誉</span></el-menu-item>
          <!-- <el-menu-item index="aboutus" :route="{ path:'aboutus', query: { id: 2} }"><img src="@/assets/images/navicon/LDGH.png" /><span>领导关怀</span></el-menu-item> -->
          <el-menu-item index="aboutus" :route="{ path:'aboutus', query: { id: 31} }"><img src="@/assets/images/navicon/ab/zscq.png" /><span>知识产权</span></el-menu-item>
          <el-menu-item index="aboutus" :route="{ path:'aboutus', query: { id: 32} }"><img src="@/assets/images/navicon/ab/jskj.png" /><span>技术框架</span></el-menu-item>
          <el-menu-item index="aboutus" :route="{ path:'aboutus', query: { id: 33} }"><img src="@/assets/images/navicon/ab/fzlc.png" /><span>发展历程</span></el-menu-item>
          <el-menu-item index="aboutus" :route="{ path:'aboutus', query: { id: 38} }"><img src="@/assets/images/navicon/ab/lxwm.png" /><span>联系我们</span></el-menu-item>
        </el-submenu >
        <el-menu-item index="news">新闻资讯</el-menu-item>
      </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
  // import { useRouter } from 'vue-router'
  import { useStore } from "vuex";
  import { computed, ref,watch } from "vue";
  import { useRoute } from 'vue-router'
  import 'animate.css'
  export default {
    props: {
      isScrollTop:{
        type:Boolean,
        default:false
      }
      
    },
    setup() {
      const state = computed(() => useStore().state.client);
      const activeIndex = ref('home')
      const route = useRoute()
      // let router = useRouter();
      function handleSelect(index){
        // console.log(index)
        if(index == "productList"){
          // router.push({
          //     //传递参数使用params的话，只能使用name指定(在route.js里面声明name)
          //     name:"productList",
          //     params:{
          //         index:index
          //     }
          // })
        }
        
      }
      watch(()=>route.path,()=>{
        activeIndex.value!==route.path.substring(1)?activeIndex.value=route.path.substring(1):null
        // console.log(activeIndex.value) 
      })
      return {
        state,
        activeIndex,
        handleSelect
      };
    },
  };
</script>

<style lang='scss'>
.pc {
  .container {
    position: fixed;
    width: 12rem;
    height: .8rem;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    z-index: 999;
    top: 0;
    left: 50%;
    margin-left: -6rem;
    .logo{
      width: 1.1rem;
      height: 0.38rem;
      margin-top: .028rem;
      img{
        width: 100%;
        height: 100%;
      }
    }
    a{
        font-size: 0;
    }
    .el-menu{
      background: none;
        border: none;
        height: .6rem;
        .el-menu-item,.el-submenu__title{
            font-size: .2rem;
            color: #fff!important;
        }
        .el-menu-item:hover,.el-submenu__title:hover,.el-submenu__icon-arrow:hover{
          color:#f90 !important;
          background: none;
        }
        .el-submenu__icon-arrow{
          color: #fff;
        }
        .is-active{
          border-bottom:0;
          color: #fff !important;
        }
    }
  }
  .navBox{
    width: 100%;
    height: .8rem;
    background: #222435;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    .container{
      position: static;
      margin: 0 auto;
      .el-menu{
      background: none;
        border: none;
        height: .6rem;
        .el-menu-item,.el-submenu__title{
            font-size: .2rem;
            color: #fff!important;
        }
        .el-menu-item:hover,.el-submenu__title:hover,.el-submenu__icon-arrow:hover{
          color:#FDBC00 !important;
          background: none;
        }
        .el-submenu__icon-arrow{
          color: #fff;
        }
        .is-active{
          border-bottom:0;
          color: #FDBC00 !important;
          // border-bottom: 2px solid #FDBC00!important;
        }
        .is-active .el-submenu__title{
          border-bottom: 2px solid #FDBC00!important;
          color: #FDBC00!important;
        }
        .is-active .el-submenu__icon-arrow{
          color: #f90!important;
        }
    }
    }
  }
}
.mobile{
  .container{
    font-size: 0;
    box-sizing: border-box;
    padding: .2rem .1rem;
    a{
      img{
        width: 2.5rem;
      }
    }
  }
}
.is-light{
    border: none!important;
  }
  .el-popper.is-pure{padding: .2rem 0!important;}
.el-popper{
  width: 100%;
  left: 0!important;
  border-radius: 0!important;
  
  ul{
    display: flex;
    width: 10rem;
    margin: 0 auto;
    flex-wrap: wrap;
    li{
      display: flex;
      width: 20%;
      height: .80rem!important;
      font-size: 14px;
      font-weight: bold;
      margin: .1rem 2.5%;
      img{
        width: .56rem;
        height: .53rem;
        margin:.15rem .1rem 0 0;
      }
      span{
        display: block;
        line-height: .80rem;
      }
    }
    li:hover{
      background: #f7f8fa!important;
      border-radius: 5px;
    }
  }
  .el-menu--popup{
    box-shadow: 0 0 0!important;
  }
}
.container .el-menu-item:focus,.container .el-menu--horizontal>.el-menu-item:not(.is-disabled):focus{
  background-color:transparent;
}
</style>