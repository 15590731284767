import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/home/Home.vue'

const routes = [
  {
    path: '/',
    redirect: '/home'
  },{
    path: '/home',
    meta:{
      title:"首页"
    },
    name: 'Home',
    component: Home
  },{
    path: '/product',
    meta:{
      title:"硬件产品"
    },
    name: 'Product',
    component: ()=>import('../views/product/product.vue'),
    children:[]
  },
  {
    path: '/productList',
    meta:{
      title:"硬件列表"
    },
    name: 'productList',
    component: ()=>import('../views/product/productList.vue')
  },
  {
    path: '/productDetail',
    meta:{
      title:"硬件详情"
    },
    name: 'productDetail',
    component: ()=>import('../views/product/productDetail.vue')
  },
  {
    path: '/product1',
    meta:{
      title:"软件产品"
    },
    name: 'Product1',
    component: ()=>import('../views/product1/product1.vue'),
    children:[]
  },
  {
    path: '/business1',
    meta:{
      title:"数字农业"
    },
    name: 'Business1',
    component: ()=>import('../views/business/business1.vue')
  },
  {
    path: '/business2',
    meta:{
      title:"智慧农业"
    },
    name: 'Business2',
    component: ()=>import('../views/business/business2.vue')
  },
  {
    path: '/business3',
    meta:{
      title:"数字农业"
    },
    name: 'Business3',
    component: ()=>import('../views/business/business3.vue')
  },
  {
    path: '/business4',
    meta:{
      title:"数字乡村"
    },
    name: 'Business4',
    component: ()=>import('../views/business/business4.vue')
  },
  {
    path: '/business5',
    meta:{
      title:"全链溯源"
    },
    name: 'business5',
    component: ()=>import('../views/business/business5.vue')
  },
  {
    path: '/business6',
    meta:{
      title:"县域电商"
    },
    name: 'business6',
    component: ()=>import('../views/business/business6.vue')
  },{
    path: '/case',
    meta:{
      title:"成功案例"
    },
    name: 'Case',
    component: ()=>import('../views/case/case.vue')
  },{
    path: '/caseDetail',
    meta:{
      title:"成功案例详情"
    },
    name: 'caseDetail',
    component: ()=>import('../views/case/caseDetail.vue')
  },{
    path: '/news',
    meta:{
      title:"新闻资讯"
    },
    name: 'News',
    component: ()=>import('../views/news/news.vue'),
    children:[
    ]
  },
  {
    path: '/newDetail',
    meta:{
      title:"新闻详情"
    },
    name: 'newDetail',
    component: ()=>import('../views/news/newDetail.vue')
  },
  {
    path: '/aboutus',
    meta:{
      title:"关于我们"
    },
    name: 'Contact',
    component: ()=>import('../views/aboutus/aboutus.vue'),
    children:[
      
    ]
  },{
    path: '/test',
    meta:{
      title:"test"
    },
    name: 'Test',
    component: ()=>import('../views/test.vue'),
    children:[
      
    ]
  },
  // {
  //   path: '/jianjie',
  //   meta:{
  //     title:"企业简介"
  //   },
  //   name: 'Jianjie',
  //   component: ()=>import('../views/aboutus/jianjie.vue')
  // },{
  //   path: '/contact',
  //   meta:{
  //     title:"联系我们"
  //   },
  //   name: 'Contact',
  //   component: ()=>import('../views/aboutus/contact.vue')
  // },{
  //   path: '/honor',
  //   meta:{
  //     title:"来村荣誉"
  //   },
  //   name: 'Honor',
  //   component: ()=>import('../views/aboutus/honor.vue')
  // },{
  //   path: '/guanhuai',
  //   meta:{
  //     title:"领导关怀"
  //   },
  //   name: 'Guanhuai',
  //   component: ()=>import('../views/aboutus/guanhuai.vue')
  // },{
  //   path: '/knowledge',
  //   meta:{
  //     title:"知识产权"
  //   },
  //   name: 'Knowledge',
  //   component: ()=>import('../views/aboutus/knowledge.vue')
  // },{
  //   path: '/jishu',
  //   meta:{
  //     title:"技术框架"
  //   },
  //   name: 'Jishu',
  //   component: ()=>import('../views/aboutus/jishu.vue')
  // },{
  //   path: '/history',
  //   meta:{
  //     title:"发展历程"
  //   },
  //   name: 'History',
  //   component: ()=>import('../views/aboutus/history.vue')
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
