<template>
<div class="zdBox">
    <h3>Build all-channel sales terminals</h3>
    <h2  class="animate__animated animate__bounceInUp wow">搭建全渠道销售终端</h2>
    <ul class="section">
        <li v-for="(item,index) in zdList" :key="index"   class="animate__animated animate__fadeInUp wow">
            <img :src="item.icon" />
            <p>{{item.title}}</p>
        </li>
    </ul>
    <h4 class="animate__animated animate__fadeInUp wow">搭建PC+H5+APP+公众号+全网小程序的一体化销售渠道，全面覆盖销售群体</h4>
</div>
</template>
<style  lang="scss" scoped>
.zdBox{
    margin-top: 100px;
    width: 100%;
    height: 8rem;
    background: url(../../../assets/images/index/zdbg.jpg);
    background-size:cover;
    background-repeat: no-repeat;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h3{
        font-family: 'SYMedium';
        color: #FDBC00;
        line-height: 30px;
        text-align: center;
        font-weight: normal;
    }
    h2{
        text-align: center;
        color: #fff;
        font-size: 28px;
        margin: 0 0 80px;
        font-weight: normal;
        font-family: 'HYQiHei';
    }
    h4{
        width: 12rem;
        height: 1.6rem;
        line-height: 1.6rem;
        box-sizing: border-box;
        margin: 0 auto;
        background: #FFFFFF;
        box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);
        border-radius: 10px;
        font-size: .26rem;
        font-family: 'SYMedium';
        text-align: center;
         animation-delay: 0.5s;
    }
    .section{
        margin: 0;
        display: flex;
        justify-content: space-between;
        li{
            text-align: center;
            margin: .1rem .26rem;
            img{
                display: block;
                width: .6rem;
                height: .6rem;
                padding: .28rem .32rem;
                border: 2px solid #fff;
            }
            p{
                font-size: 20px;
                color: #fff;
                margin: 15px 0 80px;
                font-family: 'SYMedium';
            }
        }
        li:nth-child(2){
            animation-delay: 0.1s;
        }
        li:nth-child(3){
            animation-delay: 0.2s;
        }
        li:nth-child(4){
            animation-delay: 0.3s;
        }
        li:nth-child(5){
            animation-delay: 0.4s;
        }
    }
  }
    
</style>
<script>
import {onMounted,nextTick} from "vue";
import {WOW} from 'wowjs' 
import 'animate.css'
export default {
    props:['zdList'],
    setup(){
        onMounted(()=>{
            // console.log("ll")
            nextTick(()=>{
                var wow = new WOW({
                boxClass: 'wow',    //需要执行动画元素的Class
                animateClass: 'animated',    //animation.css动画的Class
                offset: 10,    //距离可视区域多少开始执行动画
                mobile: true,    //是否在移动设备执行动画
                live: true,    //异步加载的内容是否有效
                iteration:2
                })
                wow.init()
            })
        })
    }
}
</script>