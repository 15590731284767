<template>
  <el-row :gutter="20">
    <el-col :span="12" v-for="(item,index) in productData" :key="index" @click="href(item.id)">
      <div class="productItem animate__animated animate__fadeInUp wow">
        <div class="iconNum">{{index+1}}</div>
        <div class="center"><h2>{{item.title}}</h2><p>{{item.remark}}</p></div>
        <img :src="item.photo" class="img" />
      </div>
    </el-col>
  </el-row>
</template>
<script>
// import { useRouter } from 'vue-router'
import {onMounted,nextTick, watchEffect} from "vue";
import {WOW} from 'wowjs' 
import 'animate.css'
export default {
  name: "productData",
  props:['productData','jieshu'],
  setup(props){
    onMounted(()=>{
      //data-wow-duration：更改动画持续时间 data-wow-delay：动画开始前的延迟
      // data-wow-offset：开始动画的距离（与浏览器底部相关）
      // data-wow-iteration：动画的次数重复（无限次：infinite）
    })
      //利用定义的componentRef获取dom结构
      
    watchEffect(()=>{
      if(props.jieshu){
        nextTick(()=>{
          var wow = new WOW({
            boxClass: 'wow',    //需要执行动画元素的Class
            animateClass: 'animated',    //animation.css动画的Class
            offset: 10,    //距离可视区域多少开始执行动画
            mobile: true,    //是否在移动设备执行动画
            live: true,    //异步加载的内容是否有效
            iteration:2
          })
          wow.init()
        })
      }
      
    })
    // let router = useRouter();
    function href(){
      // router.push({
      //   //传递参数使用params的话，只能使用name指定(在route.js里面声明name)
      //   path:"/business",
      //   query:{
      //       id:id,
      //   }
      // })
    }
    return{
      href

    }
  }
};
</script>
<style  lang="scss" scoped>
.el-row{
  .el-col{
    cursor: pointer;
    .productItem{
      // height: 2.64rem;
      padding: .4rem;
      background: #F3F6F9;
      border-radius: 10px;
      margin-bottom: 20px;
      box-sizing: border-box;
      display: flex;
      .iconNum{
        width: .32rem;
        height: .32rem;
        background: #FDBC00;
        border-radius: .05rem;
        font-size: .2rem;
        text-align: center;
        line-height: .32rem;
        color: #fff;
        flex-shrink: 0;
      }
      .center{
        flex: 1;
        display: flex;
        flex-direction: column;
        h2{
          font-size: .2rem;
          color: #232438;
          line-height: .32rem;
          margin-left: .2rem;
          height: .32rem;
          font-family: 'SYHeavy';
        }
        p{
          flex: 1;
          font-size: .14rem;
          line-height: 30px;
          color: #151933;
          margin-top: .1rem;
          font-family: 'SYMedium';
        }
      }
      .img{
        width:1.28rem;
        height: 1.05rem;
        display: block;
        margin-left: .3rem;
      }
    }
  }
  .el-col:nth-child(2){
    .productItem{
      animation-delay: .2s!important;
    }
  }
  .el-col:nth-child(3n){
    .productItem{
      animation-delay: .4s;
    }
  }
}
</style>