<template>
  <div class="footer">
    <div class="footerBox">
      <div class="logoBox">
          <img src="@/assets/images/logo.png" />
          <p>客服热线：{{state.tel}}</p>
          <p>联系电话：{{state.tel}}</p>
          <p>工作时间：周一到周五 09:00-18:00</p>
      </div>
      <div class="navBox">
        <div>
          <h2>关于我们</h2>
          <ul>
           <li @click="target('aboutus',29)">企业简介</li>
            <li @click="target('aboutus',30)">来村荣誉</li>
            <li @click="target('aboutus',31)">知识产权</li>
            <li @click="target('aboutus',32)">技术框架</li>
            <li @click="target('aboutus',33)">发展历程</li>
          </ul>
        </div>
        <div>
          <h2>业务领域</h2>
          <ul>
            <li @click="target('business',0)">数字农业</li>
            <li @click="target('business',1)">智慧农业</li>
            <li @click="target('business',2)">订单农业</li>
            <li @click="target('business',3)">数字乡村</li>
            <li @click="target('business',4)">全链追溯</li>
            <li @click="target('business',5)">县域电商</li>
          </ul>
        </div>
      </div>
      <div class="code">
        <div>
          <img src="@/assets/images/xcx.jpg" />
          <h3>小程序</h3>
        </div>
        <div>
          <img src="@/assets/images/code2.jpg" />
          <h3>公众号</h3>
        </div>
      </div>
    </div>
    <div class="copyRight">{{state.copyRight}}</div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { getFooterData} from "../api/request"
import { onMounted , reactive} from "vue"
export default {
  setup(){
    const router = useRouter()
    const target = (type,id) =>{
      let rr = '/' +type
      router.push({
        path:rr,
        query:{
          id:id,
        }
      })
    }
  onMounted(()=>{
    footerData()
  })
  let state = reactive({
    tel:"",
    copyRight:""
  })
  // watch(()=>route.path,()=>{
  //   activeIndex.value!==route.path.substring(1)?activeIndex.value=route.path.substring(1):null
  // })
  function footerData(){
    getFooterData().then((res) => {
      // console.log(res)
      if(res.code == 200){
        state.tel = res.data[4].value
        state.copyRight = res.data[2].value +'(' + res.data[1].value + ')'
      }
    })
  }

  return{
    state,
    target,
    footerData
  }
}
};
</script>

<style lang="scss" scoped>
.footer {
  height: 3.9rem;
  background: #222435 ;
  color: #fff;
  font-size: .12rem;
  .footerBox {
    width: 12rem;
    padding: 80px 0 0;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logoBox{
      img{
        margin-bottom: 15px;
      }
      line-height: 30px;
      font-size: 14px;
    }
    .navBox{
    display: flex;
    text-align: center;
    div{
      margin: 0 .5rem;
      h2{
        margin: .1rem;
        font-size: 18px;
        font-weight: normal;
      }
      ul{
          li{
            font-size: 14px;
            line-height: 30px;
            cursor: pointer;
          }   
        }
    }
    
  }
  .code{
    display: flex;
    div{
      text-align: center;
      margin: 0 .2rem;
      img{
        width: 1.4rem;
        height: 1.4rem;
      }
    }
  }
  }
  .copyRight{
    text-align: center;
    margin: .3rem 0;
  }
  
}
a{
  color: #fff;
}
.pointer{
    cursor: pointer;
}
.ml{
    margin-left: .5em;
    img{
        vertical-align: middle;
        transform: translateY(-2px);
    }
}
</style>